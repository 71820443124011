<template>
  <a-result status="404" title="404" sub-title="您查找的页面不存在.">
    <template #extra>
      <a-button type="primary" @click="toHome">
          返回首页
        </a-button>
    </template>
  </a-result>
</template>
<script>
import { defineComponent, ref } from 'vue'
import store from '@/store'
import storage from 'store'
export default defineComponent({
  setup () {
    const loading = ref(false)
    return {
      loading

    }
  },

  methods: {
    toHome () {
      const port = store.state.user.port || storage.get('PORT')

      this.$router.push({ name: port })
    }
  }
})
</script>
